import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalPayment from './PayPalPayment';
import './Cart.css'; // Import a CSS file for styling
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify'; // Import Auth from aws-amplify
import db from '../../Firebase'; // Import the Firebase instance

const Cart = ({ cart, onCheckout, onRemoveFromCart, firestoreCartItems, onRemoveFCart }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '' });
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if the user is signed in
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch(() => {
        // If not signed in, redirect to sign-in page
        navigate('/signin');
      });
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Access Firestore collection and add the form data
      await db.collection('formSubmissions').add(formData);
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const initialOptions = {
    clientId: "AViHP8zjWwStoVsjtzPNaVlXAIqkmnODRsqKLXbXvrJYCwRDNy6q9fxZREn61D-Iyydjlpz5W178RPXc",
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="cart-container">
        <div className="left-column">
          <h2>Checkout</h2>
          {user && (
            <form id="myForm" onSubmit={handleSubmit} className="transparent-form">
              <label htmlFor="firstName">First Name:</label>
              <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />

              <label htmlFor="lastName">Last Name:</label>
              <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />

              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

              <button type="submit">Submit</button>
            </form>
          )}
          <PayPalPayment cart={cart} />
        </div>
        <div className="right-column">
          <h2>Your Shopping Cart</h2>
          {firestoreCartItems.length ? (
            <div className="cart-items-container">
              {firestoreCartItems.map((item, index) => (
                <Card key={`${item.id}-${index}`} className="cart-item">
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {item.productName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Price: {typeof item.price === 'number' ? `$${item.price.toFixed(2)}` : `$${parseFloat(item.price).toFixed(2)}`}
                    </Typography>
                    <img src={item.photo} alt={item.productName} style={{ width: '100px', height: '100px' }} />
                    <button onClick={() => onRemoveFCart(item.id)}>Remove from Cart</button>
                  </CardContent>
                </Card>
              ))}
            </div>
          ) : (
            <p>No items in cart</p>
          )}
          <div className="cart-summary">
            <h3>Cart Summary</h3>
            <p>Total Items: {cart ? cart.total_items : 0}</p>
            <p>Total Price: {cart && cart.subtotal && cart.subtotal.formatted_with_symbol}</p>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default Cart;
