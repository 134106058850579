import React, { useState } from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, Button, Snackbar } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Alert from '@mui/material/Alert'; // Import the Alert component

const Product = ({ product, onAddToCart }) => {
  const [showMessage, setShowMessage] = useState(false);

  const truncatedName = product.name.length > 8 ? `${product.name.slice(0, 8)}...` : product.name;
  const displayPrice = product.price === 0 || product.price === '' ? 'Free' : `$${product.price}.00`;

  const handleAddToCart = () => {
    onAddToCart(product.id, 1);
    setShowMessage(true); // Show the message when adding to cart
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  return (
    <Card sx={{ maxWidth: 345, marginBottom: '20px' }}>
      <CardMedia component="img" height="140" image={product.photoUrl} alt={product.name} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {truncatedName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
        <Typography variant="h6" color="text.secondary" sx={{ marginTop: 1 }}>
          {displayPrice}
        </Typography>
        <Typography variant="caption" color="text.secondary" sx={{ marginTop: 1 }}>
          Created by: {product.username}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={handleAddToCart}>
          ADD TO CART
        </Button>
      </CardActions>
      <Snackbar open={showMessage} autoHideDuration={2000} onClose={handleCloseMessage}>
        <Alert onClose={handleCloseMessage} severity="success">
          Product added to cart!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default Product;
