import React, { useState } from 'react';
import './PaymentSuccessPage.css'; // Import the CSS file
import db from '../../Firebase'; // Import the Firebase instance

const PaymentSuccessPage = () => {
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Access Firestore collection and add the form data
      await db.collection('formSubmissions').add(formData);
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="header-container">
        <h1 className="header">Payment Successful!</h1>
        <h1 className="header">Thank you for your purchase!</h1>
        <h1 className="header">Please complete the following form to reserve your spot:</h1>
      </div>
      <div className="container">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <label htmlFor="firstName">First Name:</label>
            <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
            <label htmlFor="lastName">Last Name:</label>
            <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
