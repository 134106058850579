import React from 'react'
import '../../App.css'
import HeroSection from '../HeroSection.js'
import Cards from '../Cards'
import './Home.css'

function Home(user){
    return(
        <>
        <Cards/>
        <HeroSection/>
        
        </>
        
    );
}
export default Home;